<script>
import Thing from '../../../data-cycle-widget/src/widget/components/contents/Thing'

export default {
  extends: Thing,
  methods: {
    renderFallbackImage(event) {
      event.target.src = require('../../../public/img/no_path.png')
    }
  }
}
</script>
