<template>
  <div class="content-popup-container" itemscope :itemtype="contentType(content)">
    <div class="image-wrapper">
      <figure :class="{ 'show-placeholder-image': showFallbackImage }">
        <span class="placeholder-img" v-if="showFallbackImage">
          <font-awesome-icon :icon="['far', 'image']" />
        </span>
        <img v-else :src="imageUrl" :alt="imageAlt" itemprop="image" @error="renderFallbackImage" />
      </figure>
    </div>
    <div class="info">
      <div class="content-name">
        <h5 itemprop="name">{{ content.name }}</h5>
      </div>

      <div v-if="address" class="content-address">
        <div itemprop="address" itemscope :itemtype="contentType(this.content.address)">
          <font-awesome-icon icon="map-marker-alt" class="dark-gray" />
          <span v-html="address"></span>
        </div>
      </div>

      <div v-if="phone" class="content-phone">
        <div>
          <font-awesome-icon icon="phone" class="dark-gray" />
          <a :href="'tel:' + phone" v-html="phone" itemprop="telephone"></a>
        </div>
      </div>

      <div v-if="url" class="content-url">
        <div>
          <font-awesome-icon icon="link" class="dark-gray" />
          <a :href="url" target="_blank" v-html="url" itemprop="url"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Thing from './Thing'

export default {
  extends: Thing,
  computed: {
    address() {
      let address = this._.get(this.content, 'address')
      if (!address) return

      let addressBlock = this._.chain(address)
        .pick(['postalCode', 'addressLocality', 'streetAddress'])
        .transform((result, value, key) => {
          if (value && value.trim().length > 0) result.push('<span itemprop="' + key + '">' + value.trim() + '</span>')
        }, [])
        .join('')
        .value()

      return addressBlock
    },
    phone() {
      return this._.get(this.content, 'address.telephone')
    },
    url() {
      return this._.get(this.content, 'address.url')
    }
  }
}
</script>
