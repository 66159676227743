<template>
  <div class="content-popup-container" itemscope :itemtype="contentType(content)">
    <div class="image-wrapper">
      <figure :class="{ 'show-placeholder-image': showFallbackImage }">
        <span class="placeholder-img" v-if="showFallbackImage">
          <font-awesome-icon :icon="['far', 'image']" />
        </span>
        <img v-else :src="imageUrl" :alt="imageAlt" itemprop="image" @error="renderFallbackImage" />
      </figure>
    </div>
    <div class="info">
      <div class="content-name">
        <h5 itemprop="name">{{ content.name }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: Object,
    locale: String
  },
  data() {
    return {
      showFallbackImage: false
    }
  },
  computed: {
    imageUrl() {
      return this._.chain(this.content)
        .get('image[0].thumbnailUrl')
        .replace(/https?:\/\//, '//')
        .value()
    },
    imageAlt() {
      return this._.get(this.content, 'image[0].name')
    }
  },
  methods: {
    contentType(content) {
      return (
        'https://schema.org/' +
        this._.chain(content)
          .get('@type', [])
          .castArray()
          .reject(v => this._.startsWith('dcls:'))
          .last()
          .value()
      )
    },
    renderFallbackImage(event) {
      this.showFallbackImage = true
    }
  }
}
</script>
