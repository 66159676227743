<template>
  <div class="content-popup-container" itemscope :itemtype="contentType(content)">
    <div class="image-wrapper">
      <figure :class="{ 'show-placeholder-image': showFallbackImage }">
        <span class="placeholder-img" v-if="showFallbackImage">
          <font-awesome-icon :icon="['far', 'image']" />
        </span>
        <img v-else :src="imageUrl" :alt="imageAlt" itemprop="image" @error="renderFallbackImage" />
      </figure>
    </div>
    <div class="info">
      <div class="content-name">
        <h5 itemprop="name">{{ content.name }}</h5>
      </div>

      <div class="event-date" v-if="schedule">
        <div v-if="eventDate" :title="nextDates" :class="{ 'with-tooltip': nextDates }">
          <font-awesome-icon :icon="['far', 'calendar']" class="dark-gray" />
          <span v-html="eventDate"></span>
        </div>
      </div>

      <div v-if="location" class="content-address">
        <div itemprop="location" itemscope :itemtype="contentType(this.content.location[0])">
          <font-awesome-icon icon="map-marker-alt" class="dark-gray" />
          <span v-html="location"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Thing from './Thing'
import moment from 'moment'
import schedule from './../../mixins/schedule.js'

export default {
  extends: Thing,
  mixins: [schedule],
  computed: {
    schedule() {
      return this.createSchedule(this.content)
    },
    eventDate() {
      moment.locale(this.locale)
      let startDate =
        this.content.startDate && moment(this.content.startDate) && moment(this.content.startDate).format('L')
      let endDate = this.content.endDate && moment(this.content.endDate) && moment(this.content.endDate).format('L')
      let startTime =
        this.content.startDate && moment(this.content.startDate) && moment(this.content.startDate).format('HH:mm')
      let endTime = this.content.endDate && moment(this.content.endDate) && moment(this.content.endDate).format('HH:mm')

      let dateHTML = '<span class="eventDateWithoutTime">'

      if (startDate)
        dateHTML += '<span itemprop="startDate" content="' + this.content.startDate + '">' + startDate + '</span>'
      if (endDate && endDate != startDate) {
        if (startDate) dateHTML += ' - '

        dateHTML += '<span itemprop="endDate" content="' + this.content.endDate + '">' + endDate + '</span>'
      }
      dateHTML += '</span>'
      if ((startTime && startTime !== '00:00') || (endTime && endTime !== '00:00')) dateHTML += '<br />'
      if (startTime && startTime !== '00:00') dateHTML += startTime
      if (endTime && endTime !== '00:00' && endTime != startTime) {
        if (startTime && startTime !== '00:00') dateHTML += ' - '
        dateHTML += endTime
      }
      if ((startTime && startTime !== '00:00') || (endTime && endTime !== '00:00'))
        dateHTML += ' ' + this.$i18n.t('clock')

      return dateHTML
    },
    location() {
      const location = this._.chain(this.content)
        .get('location')
        .first()
        .value()

      if (!location) return

      let name = []

      if (location.name) name.push('<span itemprop="name">' + location.name.trim() + '</span>')

      return this._.chain(location)
        .get('address')
        .pick(['addressLocality', 'telephone', 'url'])
        .transform((result, value, key) => {
          if (value && value.trim().length > 0) {
            switch (key) {
              case 'telephone':
                result.push(
                  `<span class="tel-link"><a href="tel:${value.trim()}" itemprop="${key}">${value.trim()}</a></span>`
                )
                break
              case 'url':
                result.push(
                  `<span class="url-link"><a href="${value.trim()}" target="_blank" itemprop="${key}">${this._.chain(
                    value
                  )
                    .replace(/(^\w+:|^)\/\//, '')
                    .split('/')
                    .shift()
                    .value()}</a></span>`
                )
                break
              case 'addressLocality':
                result.unshift(
                  '<span itemprop="address" itemscope itemtype="' +
                    this.contentType(location.address) +
                    '"><span itemprop="addressLocality">' +
                    value.trim() +
                    '</span></span>'
                )
                break
            }
          }
        }, name)
        .join('')
        .value()
    },
    allFutureDates() {
      return this.schedule.between(
        moment()
          .utc()
          .toDate(),
        moment()
          .add(1, 'y')
          .utc()
          .toDate(),
        true
      )
    },
    hasFutureDates() {
      return !!this.schedule.after(
        moment()
          .utc()
          .toDate(),
        true
      )
    },
    nextDates() {
      if (!this.hasFutureDates) return null

      let dateList = this.$tc('next_dates', this.allFutureDates.length) + ':\n\n'

      this.allFutureDates.forEach(futureDate => {
        dateList += moment(futureDate).format('Do MMM YYYY, HH:mm') + ' ' + this.$t('clock') + '\n'
      })

      return dateList
    }
  }
}
</script>
