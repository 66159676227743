<template>
  <div :id="appId" class="datacycle-widget vld-parent">
    <loading :active.sync="isLoading" :is-full-page="false"></loading>
    <div id="datacycle-map-widget" :style="mapStyle"></div>
  </div>
</template>

<script>
import urlJoin from 'url-join'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import GeoJSON from 'geojson'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import qs from 'qs'
import Thing from './components/contents/Thing'
import Event from './components/contents/Event'
import Place from './components/contents/Place'
const Organization = Place,
  TouristAttraction = Place,
  LodgingBusiness = Place,
  FoodEstablishment = Place,
  LocalBusiness = Place,
  Örtlichkeit = Place
export const ALLOWED_TYPES = {
  Event,
  Place,
  Thing,
  Organization,
  TouristAttraction,
  LodgingBusiness,
  FoodEstablishment,
  LocalBusiness,
  Örtlichkeit
}
import Vue from 'vue'

export default {
  components: {
    Loading
  },
  props: {
    apiHost: {
      type: String,
      default: process.env.VUE_APP_API_HOST || process.env.BASE_URL
    },
    apiEndpoint: {
      type: String,
      default: process.env.VUE_APP_API_ENDPOINT || ''
    },
    locale: {
      type: String,
      default: process.env.VUE_APP_LOCALE || 'de'
    },
    appId: {
      type: String,
      default: process.env.VUE_APP_ID || 'dataCycle-widget'
    },
    mapWidth: {
      type: String,
      default: '100%'
    },
    mapHeight: {
      type: String,
      default: '600px'
    },
    mapZoom: {
      type: Number,
      default: process.env.VUE_APP_MAP_ZOOM || 5
    },
    mapLon: {
      type: Number,
      default: process.env.VUE_APP_MAP_CENTER_LON || 12.2
    },
    mapLat: {
      type: Number,
      default: process.env.VUE_APP_MAP_CENTER_LAT || 48.4
    },
    markerHeight: {
      type: Number,
      default: 44
    }
  },
  data() {
    return {
      showScrollOverlay: false,
      mouseZoomTimeout: null,
      contents: {
        meta: {
          pages: 1
        },
        data: []
      },
      loadedContentDetails: {},
      page: 1,
      apiParams: {
        fields:
          'name,geo.longitude,geo.latitude,location.geo.longitude,location.geo.latitude,contentLocation.geo.longitude,contentLocation.geo.latitude',
        page: { number: 1 }
      },
      map: {},
      geodata: {
        type: 'FeatureCollection',
        features: []
      },
      mapStyle: {
        width: this.mapWidth,
        height: this.mapHeight
      },
      isLoading: true
    }
  },
  computed: {
    allowedTypes() {
      return ALLOWED_TYPES
    },
    allowedTypesStrings() {
      return this._.chain(this.allowedTypes)
        .keys()
        .map(v => this._.toString(v))
        .value()
    },
    apiUrl() {
      return urlJoin(this.apiHost, 'endpoints', this.endpoint)
    },
    endpoint() {
      let urlParams = qs.parse(window.location.search, {
        ignoreQueryPrefix: true
      })

      if (
        urlParams &&
        urlParams.data_cycle_widget &&
        urlParams.data_cycle_widget.endpoint &&
        urlParams.data_cycle_widget.endpoint.length
      )
        return urlParams.data_cycle_widget.endpoint
      else return this.apiEndpoint
    }
  },
  created() {},
  mounted() {
    this.$i18n.locale = this.locale

    this.setupMap()
  },
  methods: {
    contentTemplate(item) {
      const type =
        this._.chain(item)
          .get('@type', [])
          .castArray()
          .reverse()
          .intersection(this.allowedTypesStrings)
          .first()
          .value() || 'Thing'

      return this.allowedTypes[type]
    },
    createGeoJSON() {
      return GeoJSON.parse(this.contents.data, {
        Point: ['geo.latitude', 'geo.longitude'],
        include: ['name', '@id']
      })
    },
    loadGeoData() {
      return new Promise((resolve, reject) => {
        this.loadContents(resolve, reject)
      })
    },
    loadContents(resolve, reject) {
      this.apiParams.page.number = this.page
      this.axios
        .post(this.apiUrl, this.apiParams)
        .then(response => {
          this.page++
          if (response.data['@graph'].length) {
            const contents = this.removeContentsWithMissingCoordinates(response.data['@graph']) || []
            this.contents.data.push(...contents)
            this.contents.meta = response.data.meta
          }

          if (this.page <= this.contents.meta.pages) {
            this.loadContents(resolve, reject)
          } else {
            resolve()
          }
        })
        .catch(error => {
          if (error.response) {
            alert(error.response.status + ' ' + error.response.statusText + '\n' + error.response.data.error)
          } else if (error.request) {
            console.warn(error.request)
          } else {
            console.warn('Error', error.message)
          }
          console.warn(error.config)
          this.isLoading = false
        })
        .finally(() => {})
    },

    loadContentForId(id) {
      let url = urlJoin(this.apiHost, 'detail', id)
      let params = {
        include: 'image,location,eventSchedule',
        section: {
          '@context': 0
        }
      }
      return this.axios
        .post(url, params)
        .then(response => {
          if (response.data) {
            if (Object.prototype.hasOwnProperty.call(response.data, '@graph')) {
              return response.data['@graph'][0]
            }
            return response.data
          }
        })
        .catch(e => {
          console.warn(e)
        })
    },

    removeContentsWithMissingCoordinates(graph) {
      return this._.filter(graph, element => {
        if (
          element.hasOwnProperty('geo') &&
          element.geo.hasOwnProperty('latitude') &&
          element.geo.hasOwnProperty('longitude')
        ) {
          return true
        } else if (element.hasOwnProperty('location')) {
          return this.checkForFallbackCoordinates(element, element.location)
        } else if (element.hasOwnProperty('contentLocation')) {
          return this.checkForFallbackCoordinates(element, element.contentLocation)
        } else {
          return false
        }
      })
    },

    checkForFallbackCoordinates(element, fallbackLocation) {
      for (const loc of fallbackLocation) {
        if (loc.hasOwnProperty('geo') && loc.geo.hasOwnProperty('latitude') && loc.geo.hasOwnProperty('longitude')) {
          this.replaceCoordinatesWithLocation(element, loc)
          return true
          break
        }
      }
    },

    replaceCoordinatesWithLocation(element, loc) {
      element.geo = loc.geo
    },

    loadImage: (map, key, imageUrl) => {
      return new Promise((resolve, reject) => {
        if (!map || !imageUrl) reject()
        map.loadImage(imageUrl, (error, image) => {
          if (!!error) {
            return reject(error)
          }
          map.addImage(key, image)
          resolve()
        })
      })
    },

    setupMap() {
      // TODO: make images a config parameter
      var imagesLoaded = []

      var imageUrls = {
        clustered: require('./assets/img/cluster_default.png'),
        default: require('./assets/img/cluster_default_blank.png')
      }

      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN

      this.map = new mapboxgl.Map({
        container: 'datacycle-map-widget',
        style: 'mapbox://styles/mapbox/light-v10',
        center: [this.mapLon, this.mapLat],
        zoom: this.mapZoom
      })

      for (const key in imageUrls) {
        if (imageUrls.hasOwnProperty(key)) {
          const value = imageUrls[key]
          imagesLoaded.push(this.loadImage(this.map, key, value))
        }
      }

      Promise.all(imagesLoaded).then(() => {
        this.addLayers()
        this.addListeners()

        // resize to remove unwanted borders
        this.map.resize()
      })
    },

    addLayers(markerSource) {
      this.map.on('load', () => {
        this.map.addSource('pois', {
          type: 'geojson',
          data: this.geodata,
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50
        })

        this.map.addLayer({
          id: 'clusters',
          type: 'symbol',
          source: 'pois',
          filter: ['has', 'point_count'],
          layout: {
            'icon-image': 'clustered',
            'icon-anchor': 'bottom'
          }
        })

        this.map.addLayer({
          id: 'points',
          type: 'symbol',
          source: 'pois',
          filter: ['!', ['has', 'point_count']],
          layout: {
            'icon-image': 'default',
            'icon-anchor': 'bottom'
          }
        })

        this.addGeodata()
      })
    },

    async addGeodata() {
      await this.loadGeoData()
      if (this.contents.data && this.contents.data.length) {
        let source = this.createGeoJSON()
        this.map.getSource('pois').setData(source)
        this.isLoading = false

        // fit map to bounds of loaded data
        var bounds = new mapboxgl.LngLatBounds()
        source.features.forEach(function(feature) {
          bounds.extend(feature.geometry.coordinates)
        })
        this.map.fitBounds(bounds)
      } else this.isLoading = false
    },

    addListeners() {
      // inspect a cluster on click
      this.map.on('click', 'clusters', e => {
        var features = this.map.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        })
        var clusterId = features[0].properties.cluster_id
        this.map.getSource('pois').getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return

          this.map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          })
        })
      })

      this.map.on('mouseenter', 'clusters', () => {
        this.map.getCanvas().style.cursor = 'pointer'
      })
      this.map.on('mouseleave', 'clusters', () => {
        this.map.getCanvas().style.cursor = ''
      })

      //show Popup for points
      this.map.on('click', 'points', async e => {
        var coordinates = e.features[0].geometry.coordinates.slice()
        var content = await this.createPopupContent(e.features[0].properties)

        if (!content) return

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }

        new mapboxgl.Popup({ offset: [0, -this.markerHeight] })
          .setLngLat(coordinates)
          .setDOMContent(content.$el)
          .addTo(this.map)
      })

      // Change the cursor to a pointer when the mouse is over the points layer.
      this.map.on('mouseenter', 'points', () => {
        this.map.getCanvas().style.cursor = 'pointer'
      })

      // Change it back to a pointer when it leaves.
      this.map.on('mouseleave', 'points', () => {
        this.map.getCanvas().style.cursor = ''
      })

      // Prevent scroll-wheel-zooming without key
      // TODO: mobile
      this.map.on('wheel', event => {
        let scrollOverlay = document.getElementsByClassName('scroll-overlay')[0]

        if (!scrollOverlay) {
          let container = document.getElementsByClassName('mapboxgl-canvas-container')[0]

          scrollOverlay = document.createElement('div')
          scrollOverlay.classList.add('scroll-overlay')

          let scrollText = document.createElement('div')
          scrollText.classList.add('scroll-overlay-text')
          scrollText.innerHTML = this.$t('scroll_on_map')

          scrollOverlay.appendChild(scrollText)
          container.prepend(scrollOverlay)
        }

        if (event.originalEvent.ctrlKey) {
          scrollOverlay.classList.remove('scroll-overlay-visible')
          return
        }

        if (event.originalEvent.metaKey) {
          scrollOverlay.classList.remove('scroll-overlay-visible')
          return
        }

        if (event.originalEvent.altKey) {
          scrollOverlay.classList.remove('scroll-overlay-visible')
          return
        }

        scrollOverlay.classList.add('scroll-overlay-visible')

        window.clearTimeout(this.mouseZoomTimeout)
        this.mouseZoomTimeout = window.setTimeout(() => {
          scrollOverlay.classList.remove('scroll-overlay-visible')
        }, 1000)

        event.preventDefault()
      })
    },

    async createPopupContent(props) {
      const id = props['@id']
      if (this._.has(this.loadedContentDetails, id)) return this.loadedContentDetails[id]

      let obj = await this.loadContentForId(id)
      if (!obj) return
      const template = this.contentTemplate(obj)
      if (!template) return

      this.loadedContentDetails[id] = new Vue({
        ...template,
        parent: this,
        propsData: { content: obj, locale: this.locale }
      }).$mount()

      return this.loadedContentDetails[id]
    }
  },
  watch: {}
}
</script>
