<script>
import Widget, { ALLOWED_TYPES } from '../../data-cycle-widget/src/widget/App'
import Thing from './components/Thing'

const ADDITIONAL_TYPES = { Thing }

export default {
  extends: Widget,
  computed: {
    allowedTypes() {
      return this._.merge(ALLOWED_TYPES, ADDITIONAL_TYPES)
    }
  }
}
</script>
