import { RRule, RRuleSet } from 'rrule'
import moment from 'moment'

export default {
  methods: {
    createSchedule(event) {
      if (!event.hasOwnProperty('eventSchedule')) {
        return
      }

      const schedules = event.eventSchedule

      const freqMapping = {
        Y: RRule.YEARLY,
        M: RRule.MONTHLY,
        W: RRule.WEEKLY,
        D: RRule.DAILY
      }

      const rruleSet = new RRuleSet()
      for (const s of schedules) {
        if (s.hasOwnProperty('startDate')) {
          const options = {
            dtstart: moment([s.startDate, s.startTime].join(' ').trim())
              .utc()
              .toDate(), // UTC recommended by rrule.js
            count: s.repeatCount,
            tzid: s.scheduleTimezone,
            bymonth: s.byMonth,
            bymonthday: s.byMonthDay
          }

          if (s.hasOwnProperty('endDate')) {
            options.until = moment([s.endDate, s.endTime].join(' ').trim())
              .utc()
              .toDate() // UTC recommended by rrule.js
          }

          if (s.hasOwnProperty('repeatFrequency')) {
            options.freq = freqMapping[s.repeatFrequency.slice(-1)]
            // get interval from ISO Duration e.g. 12 from P12D
            options.interval = parseInt(s.repeatFrequency.match(/\d/g).join(''), 10)
          }

          const rule = new RRule(options)

          rruleSet.rrule(rule)
        }

        // until changed in API
        if (s.hasOwnProperty('dc:additionalDate')) {
          for (const d of s['dc:additionalDate']) {
            rruleSet.rdate(
              moment(d)
                .utc()
                .toDate()
            )
          }
        }
      }
      return rruleSet
    }
  }
}
