import Vue from 'vue'
import App from './App.vue'

// Translations
import i18n from '../i18n'
App.i18n = i18n

// CSS
import './assets/css/main.scss'

// Lodash
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, { lodash: lodash })

// Custom HTML Element
import 'document-register-element/build/document-register-element'
import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement)

// FontAwesome
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinner, faMapMarkerAlt, faCalendarAlt, faPhone, faLink } from '@fortawesome/free-solid-svg-icons'
import { faCalendar, faImage } from '@fortawesome/free-regular-svg-icons'

config.autoAddCss = false
library.add(faSpinner, faMapMarkerAlt, faCalendarAlt, faImage, faCalendar, faPhone, faLink)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Axios
import axios from 'axios'
import VueAxios from 'vue-axios'
import qs from 'qs'

axios.defaults.paramsSerializer = params => {
  return qs.stringify(params, { encode: true, arrayFormat: 'brackets' })
}

Vue.use(VueAxios, axios)

// AppSignal
import Appsignal from '@appsignal/javascript'
import { errorHandler } from '@appsignal/vue'

if (process.env.NODE_ENV == 'production' && process.env.VUE_APP_APPSIGNAL_KEY) {
  const appsignal = new Appsignal({
    key: process.env.VUE_APP_APPSIGNAL_KEY
  })

  Vue.config.errorHandler = errorHandler(appsignal, Vue)
}

// Vue Config
Vue.config.productionTip = false

export { App, Vue, i18n }
